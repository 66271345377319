import Mint from './components/Mint';
import Welcome from "./components/Welcome";
import Introduction from "./components/Introduction";
import Summary from './components/Summary';
import Mission from './components/Mission';
import Charity from './components/Charity';
import Social from './components/Social';
import PriceInfo from './components/PriceInfo';
import Rarity from './components/Rarity';
import Faq  from './components/Faq';
import Utility from './components/Utility';
import Roadmap from './components/Roadmap';
import Polls from './components/Polls';
import Team from './components/Team';
import Footer from './components/Footer';

function App() {
  return (
    <>
        <Mint/>
        <Welcome/>
        <Introduction/>
        <Summary/>
        <Mission/>
        <Charity/>
        <Social/>
        <PriceInfo/>
        <Rarity/>
        <Faq/>
        <Utility/>
        <Roadmap/>
        <Team />
        <Polls/>
        <Footer/>
    </>
  );
}

export default App;
