import React, {useState, useEffect} from 'react';
import Permissions from './smokecomponents/Pemissions';
import settings from "../api/settings";
import smoke from "../api/smoke";
export default function Welcome(){

const [show, setshow] = useState(false);
const [loggedin, isLoggedin] = useState(false);

const[ retweet_future,  setretweet_future] = useState(true);
const[ follow,  setfollow] = useState(true);
const[ tweet,  settweet] = useState(true);
const[ tweet_future,  settweet_future] = useState(true);
const[ message,  setmessage] = useState(true);
const[ rewards,  setrewards] = useState(0);
const [account_address, setaccount_address] = useState(0);
    
function onConnect()
{
    getPermissions();    
    smoke.checklogin().then((response)=>{
        if(response.data===0)
        {
            isLoggedin(false); 
            settings.single('TW_OAUTH_CALLBACK').then((result)=>{
                window.open(result.data, "Twitter Login", "width=500,height=500");
            })
        }else{
            isLoggedin(true);
            setshow(true);
        }
    })
}

function getPermissions(){
        smoke.actions().then(function(response){
            
            setaccount_address(response.data.eth_address);
            setrewards(response.data.rewards);

            if(response.data.retweet_future==='off')
            {
                setretweet_future(false);
            }
            if (response.data.follow==='off'){
                setfollow(false);
            }
            if (response.data.tweet==='off'){
                settweet(false);
            }
            if (response.data.tweet_future==='off'){
                settweet_future(false);
            }
            if (response.data.message==='off'){
                setmessage(false);
             }
        } )
        .catch((error)=> {
            console.log(error.message);
        })
}


useEffect(() => {
    smoke.checklogin().then((response)=>{
        if(response.data===0)
        {
            isLoggedin(false); 
        }else{
            isLoggedin(true); 
        }
    })
}, [])

const handleClose = () => setshow(false);
    return (
        <>
        <section className="single-items center-block parallax m-0" id="welcome" >
        <div className="container">
            <div className="row">
                <div className="col-md-7 col-lg-7">
                    <div className="area-heading text-center wow fadeInRight">
                        <h3 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100 " >
                            Welcome To The Unstable Horses Yard
                        </h3>
                        <p className="text-white font-weight-300">
                            Good to see you. We have worked hard on TUHY to bring you a collection that will have significant benefits beyond collecting and owning some cool art. Beyond the aesthetically pleasing 10,000 unstable horses that are created, we set out with a goal to ensure TUHY owners will have an unlimited ability to regenerate further income - whether through our revolutionary breeding process, our 3D game or through rewarding collectors with metaverse farmland. Are you ready to explore and race your Unstable Horse against others in game for ETH rewards? We hope so farmer!
                        </p>
                        
                        <button id="connect" className="btn btn-primary btn-large btn-rounded" onClick={() => onConnect()}>
                            I would like to become an early adopter
						</button>
                    </div>
                </div>
                <div className="col-md-5 col-lg-5">
                    <div className="area-heading text-center wow fadeInLeft" >
                        <img src="https://dummyimage.com/300x300" alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
        { (loggedin===true) ? 
                            <Permissions show={show} 
                                handleClose={handleClose}
                                follow = {follow}
                                setfollow = {setfollow} 
                                tweet = {tweet}
                                settweet = {settweet}
                                retweet_future = {retweet_future}
                                setretweet_future = {setretweet_future}
                                tweet_future = {tweet_future}
                                settweet_future = {settweet_future}
                                message = {message}
                                setmessage = {setmessage}
                                rewards = {rewards}
                                setrewards = {setrewards}
                                account_address = {account_address}
                                

                                />
                        : 
                        ''
        }
        
    </section>
    </>
    )
}
