import React, {useState, useEffect} from "react";
import './../App.css';
import { useMetamask }         from "use-metamask";
import { ethers }              from "ethers";
import Web3                    from "web3";
import Account from "../components/metamask/Account";
import smoke  from './../api/smoke'
import { useParams } from "react-router";


const Polls = () => {
    
    const { connect, metaState } = useMetamask();
    const [ web3interface ] = useState("ethers");
    let { id } = useParams();
    const [data, setData] = useState([]);
    const [eth_account, seteth_account] = useState(false);
    const [voteclicked, setVoteclicked] = useState(false);
    const [ duplicate, setDuplicate] = useState(false);
    const [eth_address, setEth_address] = useState([]);

    useEffect(() => {
        let data={id:id};
        smoke.polls_single(data).then(function(response){            
            setData([response.data]);
             })
             .catch((error)=> {
               console.log(error.message);
             })         
        return () => {
              setData([]);
        }
    }, [id]) 
    
    useEffect(() => {
        (metaState.account.length>0) ? seteth_account(true) : seteth_account(false);
        (metaState.account.length>0) ? setEth_address( [ metaState.account[0] ]) : setEth_address([]);
        
        return () => {
            seteth_account(false);
            setEth_address([]);
        }
    }, [metaState.account])

    function vote(e){
        setVoteclicked(true);
        let data = {
            btn_id      : e.target.getAttribute('id'),
            poll_id     : id,
            eth         : metaState.account[0],
        };
        smoke.vote_single(data).then(function(response){
          if(response.data === 'true'){
            e.target.setAttribute("disabled", "true");
          }else{
            setDuplicate(true);
          }
            console.log(response.data);
        }).catch((error)=> {
            console.log(error.message);
          })
    }
    
  function connectMetamask(){    
    
    setEth_address( metaState.account[0] );

    if (metaState.isAvailable && !metaState.isConnected) {
        seteth_account(true);
      (async () => {
        try {
          if (web3interface === "ethers")
            await connect(ethers.providers.Web3Provider, "any");
          else if (web3interface === "web3")
            await connect(Web3);
          else
            throw Error(`Unknown web3 interface: ${web3interface}`);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }
   
  let l = 0;
    return (
            <>
          {console.log(data)}
        <div className="bg-rebel-black text-white min-h-screen overflow-auto pt-5">    
            <div className="mx-auto flex flex-col justify-center items-center">
                <div className="flex justify-center items-center my-5">
                    <button onClick={connectMetamask} className="bg-rebel-button text-white transition-colors text-base font-semibold px-6 py-2 rounded-lg">
                        <Account/>
                    </button>
                </div>
            </div>
        
        { (eth_account) ? ( 
        
        (!data.length) ?  'Loading...' :
        (
        // <div className="bg-rebel-black text-white min-h-screen overflow-auto pt-5"> 
            <div className="mx-auto flex flex-col justify-center items-center">          
                   <div className="grid grid-cols-1 max-w-lg mx-auto">
                       <div className="col-sm-6 mx-auto">
                          <a className="router-link-active text-sm block text-gray-400 font-semibold" href="#/vote">
                             <svg className="svg-inline--fa fa-long-arrow-alt-left fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                               <path className="" fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                               </path>
                             </svg>
                             Back To Votes
                          </a>
                          <div>
                             <h1 className="text-rebel-green font-bold text-3xl mb-2">{data[l].ques}
                                <span className="text-red-400  text-base font-semibold">{data[l].status}</span> 
                             </h1>
                             <article className="text-gray-200 text-1xl lg:text-2xl font-semibold">
                                 <p>
                                 {data[l].ans}
                                 </p>
                             </article>
                             <footer className="text-gray-200 mt-4 flex flex-col gap-2">
                                Your current vote weight: {data[l].weight}
                                <span>(Only Familiars counts in this poll)</span>
                                { ( duplicate === true ) ? ( <div className="text-center text-white p-2 bg-danger"> You have already Voted for this poll. </div> ) : ' ' }
                                {data[l].btns.map( (item, index) => (
                                  <React.Fragment key={index}>
                                    <div className="flex gap-4 items-center">
                                        <button id={item.id} className="bg-rebel-black pollbutton px-6 py-2 rounded-lg border border-rebel-green disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-50" onClick={(e) => vote(e)} disabled={ ( data[l].eth_address === eth_address || data[l].status === 'closed' || voteclicked === true) ? true : false}>
                                          {item.button_text}
                                        </button>
                                        <span>{item.percentage}%</span>                                       
                                     </div>
                                  </React.Fragment>
                                ) ) } 
                             </footer>
                          </div>    
                       </div>    
                   </div> 
            </div>
        
        )
        ) : ' '

        }
        </div>
            </>
        )
}

export default Polls;