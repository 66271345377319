function Introduction(){
    return (
        <>
        <section className="single-items center-block parallax m-0" id="introduction">
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="area-heading text-center wow fadeInLeft" data-wow-delay="2s">
                            <h4 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100 " >
                                HORSES, HERE TO STAY
                            </h4>
                            <p className="text-white font-weight-300">
                                No bonding curve. No ramps. Mint price - 0.04ETH. High resolution file included, alongside full usage rights to create derivatives of TUHY as you wish. Yeeehaw!
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="area-heading text-center wow fadeInLeft" data-wow-delay="2s">
                            <h4 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100 " >
                                BREEDING CENTER
                            </h4>
                            <p className="text-white font-weight-300">
                                Ever wondered what would happen if you combined two horses, what they’d look like? Well, wonder no more my good farmer. The breeding center allows any owner with two horses to breed, and create a new horse with the features of its parents. This of course makes your new horse rarer, and the overall collection, more scarce.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="area-heading text-center wow fadeInLeft" data-wow-delay="2s">
                            <h4 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100 " >
                                THE YARD (EXCLUSIVE)
                            </h4>
                            <p className="text-white font-weight-300">
                                Only TUHY owners allowed! Get access to exclusive discord channels which bring access to members only challenges, rewards, giveaways and perks in the TUHY ecosystem. And believe us, there is going to be a lot to look forward to.
                            </p>
                        </div>
                    </div>
                
                </div>
            </div>
            
        </section>
        </>
    )
}
export default Introduction;