function Charity(){
    return (
        <section className="single-items center-block parallax" id="charity" style={{ background: "url(img/single-portfolio2.jpg)" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 center-col">
                        <h1 className="text-center text-white" style={{fontSize: "48px" }}>
                            Charity + Fund</h1>
                        <p className="Poppins text-white text-center" >
                            The Unstable Horses Yard came from a genuine love of horses,
                            (one of our team, Mustang actually takes care of her own horse IRL).
                            Due to this, we wanted to give back as our project grows to some of
                            the amazing organisations making a difference to horses around the
                            world and will update our community as soon as we have any confirmation!
                            If you’d like to contribute to the community fund, we appreciate 
                            and thank you in advance. The community fund is literally for 
                            the community, and will be used completely for the purposes of 
                            marketing, further building out cool features, and giving ETH 
                            giveaways to you. Heres the cool part too…. any amount spent 
                            will be 100% decided by YOU! Thats right, if you own a TUHY, 
                            you will have a say on what the funds are spent on.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Charity;