import { useEffect, useState } from "react";
import { useMetamask }         from "use-metamask";
import { ethers }              from "ethers";
import Web3                    from "web3";
//import Info                    from "./Info";

function MetaApp() {
  const { connect, metaState } = useMetamask();
  const [ web3interface ] = useState("ethers");

  useEffect(() => {
    if (metaState.isAvailable && !metaState.isConnected) {
      (async () => {
        try {
          if (web3interface === "ethers")
            await connect(ethers.providers.Web3Provider, "any");
          else if (web3interface === "web3")
            await connect(Web3);
          else 
            throw Error(`Unknown web3 interface: ${web3interface}`);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [metaState.isAvailable, web3interface,connect,metaState.isConnected]);

  //const handleWeb3Selector = (event) => setWeb3Interface(event.target.value);

  return (
    <div >
    </div>
  );
}

export default MetaApp;