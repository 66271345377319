import axios from "axios"
import $ from 'jquery'
const API_URL=process.env.REACT_APP_API_URL;

const smoke={
    checkanswer(data){
        return axios.post(API_URL+'/checkanswer.php',JSON.stringify(data))
    },
    single(data){
        let account=$('#account').val();
        return axios.post(API_URL+'/permission.php?eth='+account,JSON.stringify(data))
    },
    actions(){
        return axios.get(API_URL+"/actions.php");
    },
    sharetwitter(data){
        return axios.post(API_URL+'/downloadimage.php',JSON.stringify(data))
    },
    checklogin(){
        return axios.post(API_URL+'/checklogin.php')
    },
    convertgif(data){
        return axios.post(API_URL+'/gif.php',JSON.stringify(data))
    },
    fetchLastsavedData(data){
        return axios.post(API_URL+'/getlastoverlap.php',JSON.stringify(data))
    },
    fetchsavedData(data){
        return axios.post(API_URL+'/getoverlapsettings.php',JSON.stringify(data))
    },
    savelastoverlap(data){
        return axios.post(API_URL+'/savelastoverlap.php',JSON.stringify(data))
    },
    saveoverlapsettings(data){
        return axios.post(API_URL+'/saveoverlapsettings.php',JSON.stringify(data))
    },
    getsavedsmoke(data){
        return axios.post(API_URL+'/getsavedsmoke.php',JSON.stringify(data))
    },
    getaddresssettings(data){
        return axios.post(API_URL+'/getaddresssettings.php',JSON.stringify(data))
    },
    performaction(){
        return axios.post(API_URL+'/performaction.php')
    },
    polls(){
        return axios.get(API_URL+'/polls.php')
    },
    polls_single(data){
        console.log(data);
        return axios.post(API_URL+'/polls_single.php',JSON.stringify(data))        
    },
    vote_single(data){
        return axios.post(API_URL+'/polls_vote.php',JSON.stringify(data))
    },

}
export default smoke;