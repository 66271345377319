import React, { useEffect, useState } from "react";
import BuyNow from "./metamask/BuyNow";

function Timer(props) {
  const calculateTimeLeft = () => {
    //let year = new Date().getFullYear();
    const difference = +new Date(props.counterdate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
      <>
      {timerComponents.length
        ? 
        <>
        <div className="Countdown__Wrapper-sc-1q65bve-0 eDokRY text-white">
            <div className="time-section">
                <div className="time">{ timeLeft.days.toString().padStart(2, '0') }</div>
                <small className="time-text">Days</small>   
                </div>
                <div className="time-section">
                <div className="partition">:</div>   
                </div>
                <div className="time-section">
                <div className="time">{ timeLeft.hours.toString().padStart(2, '0') }</div>
                <small className="time-text">Hours</small>   
                </div>
                <div className="time-section">
                <div className="partition">:</div>   
                </div>
                <div className="time-section">
                    <div className="time">{ timeLeft.minutes.toString().padStart(2, '0') }</div>
                    <small className="time-text">Minutes</small>   
                </div>
                <div className="time-section">
                <div className="partition">:</div>   
                </div>
                <div className="time-section">
                    <div className="time">{ timeLeft.seconds.toString().padStart(2, '0') }</div>
                    <small className="time-text">Seconds</small>   
                </div>
        </div>
        <button className="btn btn-lg btn-info text-white">MINTING SOON</button>        

        </>
         : 
         <BuyNow/>
      }
      </>
    
  );
}

export default Timer;