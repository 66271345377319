import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import { Suspense } from "react";

const Model = () => {
  
  const gltf = useGLTF("glb/Wepon_Idle02.glb")
  return (
    <>
      <primitive object={gltf.scene} scale={0.8} />
    </>
  );
};

export default function Demo() {
  return (
      <Canvas>
        <Suspense fallback={null}>
          <Model />
          <OrbitControls />
          <Environment preset="sunset" />
        </Suspense>
      </Canvas>
  );
}
