import React, { Component } from "react";
import MetaApp from "./MetaApp"
import Account from "./Account";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

class BuyNow extends Component {
  constructor() {
    super();
    this.state = { 
      data: [],
      isOpen:false,
      connect:false,

    };
  }
  connectWallet = async () => {
    // bridge url
    const bridge = "https://bridge.walletconnect.org";

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    await this.setState({ connector });

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    // subscribe to events
   // await this.subscribeToEvents();
  };
  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  connectMetamask  = () => this.setState({ connect: true,isOpen: false });
  render() {
    return (
      <>
    <div className="modal" id="connectModal" role="dialog" aria-labelledby="connectModalTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          
          <div className="modal-body">
          <div className="card pointer"  onClick={this.connectMetamask}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">        
                  <img className="img-fluid" src="/img/metamask.svg" alt="" style={{ width:'50px'}}/>
              </li>
              <li className="list-group-item"><h4 className="title">MetaMask</h4></li>
              <li className="list-group-item"><p  className="description">Connect to your MetaMask Wallet</p></li>
            </ul>
          </div>
          <div className="card pointer mt-2"  onClick={this.connectWallet}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">        
                  <img className="img-fluid" src="/img/wallet.svg" alt="" style={{ width:'50px'}}/>
              </li>
              <li className="list-group-item"><h4 className="title">WalletConnect</h4></li>
              <li className="list-group-item"><p  className="description">Scan with WalletConnect to connect</p></li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" className="btn btn-danger btn-rounded btn-large mt-3" data-toggle="modal" data-target="#connectModal">
    <Account />
    </button>

      {this.state.connect ? (
          <MetaApp/>
        ) : ("")}
    </>

    );
  }
}
export default BuyNow;