import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './App';
import Polls from './pages/Polls';
import Header from './components/Header';
import DemoCanvas from './components/DemoCanvas';

export default class Routes extends Component {
    render() {
        return (
        <Router>
                <Header/>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/poll/:id' component={Polls} />
              <Route path='/demo_canvas' component={DemoCanvas} />
          </Switch>
        </Router>
        )
    }
}