function Footer() {
    return(
     <section className="" style={{background: "url(img/single-portfolio2.jpg)" }}>   
       <div className="container wow fadeInUp">
          <div className="text-white text-center">    
            <a className="footera m-3" href="#social" id="afooter"><i className="fab fa-twitter"></i></a>
            <a className="footera m-3" href="#social" id="afooter"><i className="fab fa-discord"></i></a>
          </div>
          <div className="text-white text-center">
            <p className="footerp">Terms of Service | Privacy</p>
            <p className="footerp">Copyright © 2021 Generative Art, LLC. All Rights Reserved</p>
          </div>      
       </div>
     </section>
    )
}
export default Footer;