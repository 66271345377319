function PriceInfo(){
     return (
        <section className="single-items center-block parallax bg-warning" id="price-info" style={{background: "url(img/single-portfolio2.jpg)" }}>
        <div className="container">
            <div className="row">
              <div className="col-md-12 center-col">
                  <div className="area-heading text-center wow fadeInUp">
                      <h3 className="area-title text-capitalize alt-font text-white mb-5 font-weight-900">
                        PRICE + INFO
                      </h3>
                      <p className="text-white font-weight-200 mb-3">
                         
                        One of the exciting things about Unstable Horses
                        is that our 10,000 horses are completely unique 1/1s derived from different
                        expressions, hooves, mouth, clothing and more alongside each having randomised 
                        physical traits . Although some are rarer than others, we have a revolutionary 
                        protocol to generate new (even more rare) Unstable Horses through our breeding 
                        center. More info can be found on the breeding page.
                        </p>
                        <p className="text-white font-weight-200 mb-3">
                            The horses are stored as ERC-721 tokens on the 
                            Ethereum blockchain and hosted on IPFS. There are horse club 
                            benefits which include rewards, farmland opportunities, 
                            giveaways, access to our breeding center and more!
                        </p>
                  </div>
              </div>
              
            </div>
        </div>
    </section>
     )
}
export default PriceInfo;