import React from 'react';
import { Link } from 'react-router-dom';
import {  useState, useEffect } from "react";
import { useMetamask }         from "use-metamask";
import { ethers }              from "ethers";
import Web3                    from "web3";
import Account from "./metamask/Account";
import smoke  from './../api/smoke'

function Polls(){
  
  const { connect, metaState } = useMetamask();
  const [ web3interface ] = useState("ethers");
  const [data, setdata] = useState([]);
  const [metaAcc, setmetaAcc] = useState(false);

  useEffect(()=>{
  (metaState.account.length>0) ? setmetaAcc(true) : setmetaAcc(false); 
    smoke.polls().then(function(response){
      setdata( response.data );
    })
   .catch((error)=> {
     console.log(error.message);
   })
   return () => {
      setdata([]);
   }
   },[metaState.account]);

  function connectMetamask(){
    if (metaState.isAvailable && !metaState.isConnected) {
      setmetaAcc(true);
      (async () => {
        try {
          if (web3interface === "ethers")
            await connect(ethers.providers.Web3Provider, "any");
          else if (web3interface === "web3")
            await connect(Web3);
          else 
            throw Error(`Unknown web3 interface: ${web3interface}`);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }
 return(
  
<section className="center-block" id="polls" style={{background: "url(img/single-portfolio2.jpg)" }}>
 <div className="container-fluid wow fadeInUp"> 
 <div className="max-w-4xl lg:max-w-screen-xl mx-auto px-2">
  <div className="flex justify-center items-center my-5 text-center">
   <button onClick={connectMetamask} className=" bg-rebel-green text-black  hoverbg-green-400 transition-colors text-base font-semibold px-6 py-2 round-lg">
      <Account />
    </button>   
  </div> 
  <div>
  <header className="text-4xl pollsheader text-rebel-green flex items-center justify-center text-center my-4">Polls</header>
  <article>
     { (metaAcc) ? (
        data.map((item, i) => ( 
          <React.Fragment  key={i}>
              <Link to={'/poll/'+ item.id} key={i}>
              <div className="w-full cursor-pointer bg-gray-900 bg-opacity-50 p-5 rounded-lg mb-2 border-rebel-button border">
              <header className="text-rebel-green font-700  GreycliffCF text-3xl mb-2">
              { item.ques }
                <span className="text-red-400 pl-1 text-base  font-semibold ">{item.status}</span>   
              </header>
              <article className="text-gray-200 text-1xl lg:text-2xl text-white  font-semibold">
                <p>{item.ans}</p>
              </article>
                <footer className="text-sm text-gray-200 text-white mt-2">Touch To Open</footer>
              </div>
              </Link>
          </React.Fragment>
      ))
     ) : ( data.map((item, i) => (
      <React.Fragment  key={i}>
      <div className="w-full cursor-pointer bg-gray-900 bg-opacity-50 p-5 rounded-lg mb-2 border-rebel-button border">
           <header className="text-rebel-green font-700  GreycliffCF text-3xl mb-2">
           { item.ques }
             <span className="text-red-400 pl-1 text-base  font-semibold ">{item.status}</span>   
           </header>
           <article className="text-gray-200 text-1xl lg:text-2xl text-white  font-semibold">
             <p>{item.ans}</p>
           </article>
             <footer className="text-sm text-gray-200 text-white mt-2">Touch To Open</footer>
       </div>
     </React.Fragment>
      ))
     )
    }
  </article>
  </div>
 </div>
 </div>
</section>
 )   
}
export default Polls;