function Summary(){
    return (
        <>
        <section className="single-items center-block parallax" id="summary" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 text-center">
                        <img className="img-fluid" src="https://dummyimage.com/286x400" alt="" />
                    </div>
                    <div className="col-lg-8">
                        <h1 className="minth1">MINT AN UNSTABLE HORSE FROM THE YARD</h1>
                        <p className="poppins">Each unstable horse costs just 0.04ETH, and for that you get the following:</p>
                            <ul className="mt-3">
                                <li className="poppins">1/1 Unstable Horse.</li>
                                <li className="poppins">Access to the TUHY DAO.</li>
                                <li className="poppins">Access to ‘The Yard’ a members only space where you can win rewards based on challenges and loyalty.</li>
                                <li className="poppins">ERC Token Airdrop on launch.</li>
                                <li className="poppins">Participation opportunity to own farmland in the metaverse. </li>
                                <li className="poppins">An ingame Unstable Horse to compete in 
                                    <span className="vimland">Gallop</span>
                                    – the browser based game. 
                                </li>
                            </ul>
                        <p className="Poppins text-center w-100">
                        <span className="vimland text-white" style={ {fontSize: '50px'}}>SOLD OUT</span> 
                        <br />
                        <a className="poppins" href="https://opensea.io/collection/the-unstable-horses-yard">
                            <span className="Poppins text-center" style={{ fontSize:"16px" }}>
                            However, you can buy on Opensea here 
                            <img src="https://dummyimage.com/40x40" alt="opensea" width="40px" />
                            </span>
                        </a> 
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Summary;