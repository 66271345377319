function Faq(){
    return (
    <section className="single-items center-block parallax bg-warning" id="faq" style={{ background: "url(img/single-portfolio2.jpg)" }}>
    <div className="container">
        <div className="row">
          <div className="col-md-12 center-col">
              <div className="area-heading text-center wow fadeInUp">
                  <h3 className="area-title text-capitalize alt-font text-white mb-5 font-weight-900">
                    FAQ's
                  </h3>
                </div>
            </div>
            <div className="col-md-12">
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header p-0" id="headingOne">
                          <button className="btn btn-dark w-100 btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Question #1
                          </button>
                      </div>
                  
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                          Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> class.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header p-0" id="headingTwo">
                          <button className="btn btn-dark w-100 btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Question #2
                          </button>
                      </div>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                          Some placeholder content for the second accordion panel. This panel is hidden by default.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header p-0" id="headingThree" >
                          <button className="btn btn-dark btn-block w-100 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Question #3
                          </button>
                      </div>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                          And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
    </div>
</section>
    )
}

export default Faq;