function Rarity(){
    return (
        <section className="bg-warning" id="rarity" style={{background: "url(img/single-portfolio2.jpg)" }}>
            <div className="container">
            <h2 className="font-4x text-white text-uppercase text-center mb-5 wow animated fadeInLeft font-weight-900 ">
                Rarity
            </h2>
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8">
                    <p className="text-center text-white mb-5">
                        Each NFT is algorithmically generated by combining 150+ unique
                        traits with varying rarity across categories. There is 4 types of
                        Wolf : Common, Less Common, Rare, Super Rare.
                    </p>
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="card rounded bg-light animate-box animated fadeInUp">
                                <div className="card-body">
                                    <ul className="list-unstyled m-0 text-dark">
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">10.000</strong>
                                        <span>Wolves</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">11</strong>
                                        <span>Background</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">12</strong>
                                        <span>Fur</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">11</strong>
                                        <span>Eyes</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">8</strong>
                                        <span>Mouth</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">52</strong>
                                        <span>Clothes</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">21</strong>
                                        <span>Hat</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">10</strong>
                                        <span>Glasses</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">8</strong>
                                        <span>Mouth Accessories</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">6</strong>
                                        <span>Ears Accessories</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">6</strong>
                                        <span>Necklace</span>
                                        </li>
                                        <li className="d-flex py-2 justify-content-between">
                                        <strong className="font-weight-bold">7</strong>
                                        <span>Tatoos</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-4 mt-sm-0 text-center">
                                <button className="btn btn-transparent-white btn-rounded btn-large mt-3  fadeInLeft" id="rarityYools" style={{ cursor: 'none' }}>
                                    Rarity Tools
                                </button>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </section>
    )
}
export default Rarity;