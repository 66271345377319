function Social(){
    return (
        <section className="single-items center-block parallax bg-warning" id="social">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 center-col">
                        <div className="area-heading text-center wow fadeInUp">
                            <h3 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100">
                                Join Our community 
                            </h3>
                            <p className="text-white font-weight-300">Want to join the TUHY community? Click
                                the link below and see you in the server!</p>
                            <a href="#0" className="btn btn-danger btn-rounded btn-large mt-3">JOIN OUR DISCORD</a>
                            <a href="#0" className="btn btn-info btn-rounded btn-large mt-3">JOIN OUR Twitter</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            </section>
    )
}
export default Social;