
import React from 'react';
import Timer from "./Timer";
//import Weapon from "./Models/Weapon"
import Demo from "./DemoCanvas"
import settings from './../api/settings'

export default class Mint extends React.Component{
    state = {
        counterdate: new Date()
      }
      componentDidMount() {
        settings.single('start_date').then((response)=>{
            this.setState({counterdate:response.data});
        })
        
      }

      
    render() {
    return (
        <>
        <section className="single-items center-block parallax m-0" id="mint">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-5">
                        <div className="area-heading text-center wow fadeInRight">
                            <h3 className="area-title text-capitalize alt-font text-white mb-2 font-weight-100 " >
                                WELCOME TO WOLF CLUB 86
                            </h3>
                            <p className="text-white font-weight-300">The Wolf Club 86 is a collection of 10.000 Wolves NFTs —unique digital collectibles living on the Ethereum blockchain as ERC-721.</p>
                            <h4 className="text-white">How Many Wolves do you want to mint ?</h4>
                            <div className="col-sm-8 mx-auto">
                                <div className="contact-form-textfield pb-4">
                                    <input type="text" placeholder="" className="form-control text-center" required="" defaultValue="1" />
                                </div>
                            </div>
                                <Timer counterdate={this.state.counterdate}/>
                        </div>
                    </div>  
                    <div className="col-md-7" style={{background : 'rgba(85, 93, 80, .9)' }}>
                            <Demo />
                    </div>
                </div>
            </div>
        </section>
         </>
    );
}
}