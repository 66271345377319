import React from 'react';
import { Modal, Accordion } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import smoke  from '../../api/smoke'
// import $ from 'jquery'
import './custom.css'

export default function Permissions(props) {
    // const [show, setShow] = useState(false);
    // const [follow, setFollow] = useState(true);
    // const [tweet,setTweet] = useState(true);
    // const [retweet_future,setRetweet_future] = useState(true);
    // const [tweet_future,setTweet_future] = useState(true);
    // const [message,setMessage] = useState(true);
    // const [rewards,setRewards] = useState(0);
    // const [account_address,setAccount_address] = useState(0);

    // function handleShow(){
    //     setShow(true);
    //     let address=$('#account').val();
    //     setAccount_address(address);
    //     getpemission();
    // }

    function submit() {
        console.log(props.mainState);
        let data={ 
                  follow:props.follow,
                  tweet:props.tweet,
                  retweet_future: props.retweet_future,
                  tweet_future:props.tweet_future,
                  message:props.message};
        smoke.single(data).then(function(response){
            props.setrewards(response.data)
            props.handleClose();
        } )
        .catch((error)=> {
        console.log(error.message);

        })
    }

   

    
    
    // function getpemission()
    // {
        //     smoke.actions().then(function(response){
    //         setAccount_address(response.data.eth_address);
    //         setRewards(response.data.rewards);
    //         if(response.data.retweet_future==='off')
    //         {
    //             setRetweet_future(false);   
    //         }
    //         if (response.data.follow==='off'){
    //             setFollow(false);
    //         }
    //         if (response.data.tweet==='off'){
    //             setTweet(false);
    //         }
    //         if (response.data.tweet_future==='off'){
    //             setTweet_future(false);
    //         }
    //         if (response.data.message==='off'){
    //             setMessage(false);
    //          }
    //     } )
        
    //     .catch((error)=> {
    //     console.log(error.message);

    //     })        
    // }
   
    
    return (
      <>		
        <Modal show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
                <h4 className="mt-0 text-center w-100">
                    <b>🤘 YOU ROCK! 🤘</b>
                        <br/>
                        <small>Thanks For Your Trust &amp; Support</small>
                </h4>
          </Modal.Header>
          <Modal.Body>
                 <p className="text-justify">We believe in transparency and trust … Feel free to enable/disable any of the following permissions. You CAN always come back to this screen to turn on/off anything if you change your mind.</p>
				<h4>Giveaway Entries: <span className="entries">{props.rewards}</span></h4>
				<h5>ETH Account:  <address className='address' style={{display:'contents', fontSize: '14px'}}>
                  {props.account_address}
                    </address></h5>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h4 className="float-left">
                                <div className="custom-switch custom-switch-label-onoff custom-switch-xs">
                                <BootstrapSwitchButton id="follow" checked={props.follow} size="xs" onstyle="success" offstyle="danger" onlabel="Yes" offlabel="No" onChange={() => props.setfollow( !props.follow ) }/>
                                    <label className="custom-switch-btn"></label>
                                    <span className="text-dark">Allow us to follow each other on twitter?</span>
                                </div>
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="card-body">
                                <p className="text-muted">
                                    With your permission, we will follow each other on twitter.
                                    <br/>
                                    Twitter calls this read/write permission. It's their technical way to express it. 
                                    But if this permission is ON, we will ONLY follow you and use your account to follow our twitter handle only: @sandhu0173
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        <h4 className="float-left">
                                <div className="custom-switch custom-switch-label-onoff custom-switch-xs">
                                <BootstrapSwitchButton id="tweet" checked={props.tweet} size="xs" onstyle="success" offstyle="danger" onlabel="Yes" offlabel="No" onChange={ () => props.settweet(!props.tweet) } />
                                    <label className="custom-switch-btn"></label>
                                    <span className="text-dark"> Can we post the following tweet on your timeline?</span>
                                </div>
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="card-body">
                                <p className="text-muted">
                                        This is a one-time permission…<br/>
                                        We will publicly publish the following tweet on your account. This permission does NOT permit us to post anything else in the future.<br/>
                                        <button className="btn btn-link" data-toggle="modal" data-target="#tweetModal">Click Here</button> to see what we will tweet?
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h4 className="float-left">
                                <div className="custom-switch custom-switch-label-onoff custom-switch-xs">
                                <BootstrapSwitchButton id="retweet_future" checked={props.retweet_future} size="xs" onstyle="success" offstyle="danger" onlabel="Yes" offlabel="No" onChange={ () => props.setretweet_future( !props.retweet_future ) }/>
                                    <label className="custom-switch-btn"></label>
                                    <span className="text-dark"> Can we RETWEET on your timeline? (max 1 RT /week)</span>
                                </div>
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="card-body">
                                <p className="text-muted">
                                    With your permission… Can we RT on your timeline? <br/>
                                    If permitted, we will only retweet maximum 1 time per week.
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                                <h4 className="float-left">
                                <div className="custom-switch custom-switch-label-onoff custom-switch-xs">
                                <BootstrapSwitchButton id="tweet_future" checked={props.tweet_future} size="xs" onstyle="success" offstyle="danger" onlabel="Yes" offlabel="No" onChange={ () => props.settweet_future( !props.tweet_future )} />
                                    <label className="custom-switch-btn"></label>
                                    <span className="text-dark"> Can we TWEET on your timeline (in the future)?</span>
                                </div>
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="card-body">
                                <p className="text-muted">
                                    With your permission… Can we post something on your timeline?<br/>
                                            If you permit this, it's only 1 tweet per week (maximum).
                                </p>
                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            <h4 className="float-left">
                                <div className="custom-switch custom-switch-label-onoff custom-switch-xs">
                                <BootstrapSwitchButton id="message" checked={props.message} size="xs" onstyle="success" offstyle="danger" onlabel="Yes" offlabel="No" onChange={ () => props.setmessage(!props.message)} />
                                    <label className="custom-switch-btn"></label>
                                    <span className="text-dark"> Can we send you a private message (DMs) on twitter?</span>
                                </div>
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="card-body">
                                <p className="text-muted">
                                    With your permission… we’ll DM you on twitter? <br/>
                                    We will only send you messages with important announcements, milestones and/or when we have a giveaway etc… 
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
          <Modal.Footer>
          <button onClick={props.handleClose} type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                <button onClick={submit} type="submit" name="submit" className="btn btn-success btn-sm">Save changes</button>
                <hr/>
                <p className="text-center">Please notice that even after you permit the above,  you can come back here and customize/change your decisions at any time.</p>
                <p className="text-center">You can also revoke access for our app by visiting twitter <a href="https://twitter.com/settings/connected_apps">settings here.</a></p>
        </Modal.Footer>
    </Modal>
        
		<div className="modal fade" id="tweetModal" tabIndex="-1" role="dialog" aria-labelledby="tweetModalLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content bg-dark text-light">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">Tweet Detail</h5>
						<button type="button" className="close text-light" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
							Testing Image      
					</div> 
				</div>
			</div>
		</div>
      </>
    );
  }
  