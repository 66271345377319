function Navbar() {
  return (
    <div className="navbar-nav ml-auto">
      <a href='#mint' className="nav-link scroll">Mint</a>
      <a href='#mission' className="nav-link scroll" >Mission </a>
      <a href='#roadmap' className="nav-link scroll" >Roadmap </a>
      <a href='#team' className="nav-link scroll" >Team </a>
      <a href='#about' className="nav-link scroll">About </a>
      <a href='#polls' className="nav-link scroll">Polls </a>
      <a href='#social' className ="nav-link scroll"><i className="fab fa-twitter"></i> </a>
      <a href='#social' className="nav-link scroll"><i className="fab fa-discord"></i></a>
    </div>

  );
}

export default Navbar;
