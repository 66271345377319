function Mission(){
    return (
        <section className="single-items center-block parallax bg-warning" id="mission" style={ {background:"url(img/single-portfolio2.jpg)" }}>
            <div className="container">
                <div className="row">
                <div className="col-md-12 center-col">
                    <div className="area-heading text-center wow fadeInUp">
                        <h3 className="area-title text-capitalize alt-font text-white mb-5 font-weight-900">
                            Mission
                        </h3>
                        <p className="text-white font-weight-200 mb-3">
                            Kuddle Koalas is an NFT collection that benefits children in need. Over 25% of all sales will be deposited into a community wallet. These funds will allow the team to make our future goals possible. Additionally, the community wallet will allow us to back the cost of future community endeavors.</p>
                            <img src="https://dummyimage.com/500x281" className="img-fluid" alt=""/>
                            <p className="text-white font-weight-300 mt-3">
                                Kuddle Koalas empower all holders to be someone's hero. Together, Kuddle Koalas will serve as a catalyst for other projects to follow in our footsteps and better the world, no matter the scale.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default Mission