import React           from 'react'
import { useMetamask } from "use-metamask";

export default function Account() {
    const { metaState } = useMetamask();
    if(metaState.account.length>0)
    {
      return (
        <>  
            {metaState.account[0].substring(0,5)}
              ...
            {metaState.account[0].substr(metaState.account[0].length - 5)}
        </>
    )
    }else{
      return (
        <>   
            Connect Wallet
        </>
    )
    }
}
