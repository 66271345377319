function Roadmap(){
    return (
        <section className=" center-block" id="roadmap" style={{background: "url(img/single-portfolio2.jpg)" }}>
    <div className="container wow fadeInUp">
        <div className="">
            <h2 className="roadmapheading text-center text-white" id="roadmap-heading">Roadmap</h2>
        </div>
        <div className="row">
            <div  className="col-sm-6">
                 <img alt="" className="img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225"/>
            </div>
            <div className="col-sm-6">
                <h2 className="chakra-heading " tag="h3">10% Sold</h2>
                    <ul  className="css-azmz5h">
                        <li className="purple-dot css-0">
                            <p className="chakra-text css-1h77d4h">Discord upgrades will begin, including but not limited to a server vanity URL and banner.</p>
                        </li>
                        <li className="purple-dot css-0">
                            <p className="chakra-text css-1h77d4h">Discord staffing applications become available.</p>
                        </li>
                        <li className="purple-dot css-0">
                            <p className="chakra-text css-1h77d4h">Live streams will begin where the Koala Team can answer community questions and more.</p>
                        </li>
                    </ul>
            </div>
        </div>     


                <div  className="row">
                    <div className="col-sm-6">
                                <h2 className="chakra-heading css-1dklj6k" tag="h3">20% Sold</h2>
                                <ul  className="css-azmz5h">
                                    <li className="blue-dot css-0">
                                        <p className="chakra-text css-1h77d4h">The community wallet opens up with 12 ETH, around 25%, of the prevailing sales.</p>
                                    </li>
                                    <li className="blue-dot css-0">
                                        <p className="chakra-text css-1h77d4h">Using a portion of the community wallet, the team will begin purchasing supplies such as blankets, canned goods, and clothes.</p>
                                    </li>
                                </ul>
                    </div>
                    <div className="col-sm-6">
                        <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                    </div>
                </div>

                <div  className="row">
                    <div className="col-sm-6">
                            <img alt="" className="chakra-image css-1nz97kk" aria-hidden="true" src="https://dummyimage.com/425x225" />
                    </div>
                    <div className="col-sm-6">
                                <h2 className="chakra-heading css-1dklj6k" tag="h3">30% Sold</h2>
                                <ul  className="css-azmz5h">
                                    <li className="red-dot css-0">
                                        <p className="chakra-text css-1h77d4h">Koala Team executes community idea(s), such as creating extra utility or media outreach.</p>
                                    </li>
                                    <li className="red-dot css-0">
                                        <p className="chakra-text css-1h77d4h">Competition Week will be held, where members of Kuddle Koalas can participate in events such as a logo or meme competition to boost community engagement and win prizes.</p>
                                    </li>
                                </ul>
                    </div>
                </div>
                <div  className="row">
                    <div className="col-sm-6">
                        <h2 className="chakra-heading css-1dklj6k" tag="h3">40% Sold</h2>
                        <ul  className="css-azmz5h">
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">The community wallet will have 25 ETH in total deposited at this point.</p>
                            </li>
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">Charity planning begins with requesting the community's input for date, .4time, location, and execution of our first charity event.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6">
                        <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                    </div>
                </div>
            <div className="row">
                <div className="col-sm-6">
                    <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                </div>
                <div className="col-sm-6">
                        <h2 className="chakra-heading css-1dklj6k" tag="h3">50% Sold</h2>
                        <ul  className="css-azmz5h">
                            <li className="green-dot css-0">
                                <p className="chakra-text css-1h77d4h">With input from the community, airdrops will commence and be given to unique wallet holders.</p>
                            </li>
                        </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <h2 className="chakra-heading css-1dklj6k" tag="h3">60% Sold</h2>
                    <ul  className="css-azmz5h">
                        <li className="peach-dot css-0">
                            <p className="chakra-text css-1h77d4h">A live stream of the Kuddle Koala drawing process takes place, where members can request a custom Koala. One custom Koala will be selected, drawn, and airdropped to a lucky member.</p>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-6">
                    <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                </div>
                <div className="col-sm-6">
                    <h2 className="chakra-heading css-1dklj6k" tag="h3">70% Sold</h2>
                    <ul  className="css-azmz5h">
                        <li className="blue-dot css-0">
                            <p className="chakra-text css-1h77d4h">The community wallet will have 45 ETH in total deposited at this point.</p>
                        </li>
                        <li className="blue-dot css-0">
                            <p className="chakra-text css-1h77d4h">Unrevealed</p>
                        </li>
                        <li className="blue-dot css-0">
                            <p className="chakra-text css-1h77d4h">Unrevealed</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                        <h2 className="chakra-heading css-1dklj6k" tag="h3">80% Sold</h2>
                        <ul  className="css-azmz5h">
                            <li className="peach-dot css-0">
                                <p className="chakra-text css-1h77d4h">A live stream of the Kuddle Koala drawing process takes place, where members can request a custom Koala. One custom Koala will be selected, drawn, and airdropped to a lucky member.</p>
                            </li>
                        </ul>
                </div>
                <div className="col-sm-6">
                    <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                </div>
            </div>
            <div  className="row">
                <div className="col-sm-6">
                    <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                </div>
                <div className="col-sm-6">
                    <h2 className="chakra-heading css-1dklj6k" tag="h3">90% Sold</h2>
                    <ul  className="css-azmz5h">
                        <li className="gray-dot css-0">
                            <p className="chakra-text css-1h77d4h">The Merch Shop goes live, where there will be custom pieces, community designs, hidden prizes, and more.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div  className="row">
                    <div className="col-sm-6">
                        <h2 className="chakra-heading css-1dklj6k" tag="h3">100% Sold</h2>
                        <ul  className="css-azmz5h">
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">Unrevealed</p>
                            </li>
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">Unrevealed</p>
                            </li>
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">Unrevealed</p>
                            </li>
                            <li className="blue-dot css-0">
                                <p className="chakra-text css-1h77d4h">Unrevealed</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6">
                        <img alt="" className="chakra-image img-fluid" aria-hidden="true" src="https://dummyimage.com/425x225" />
                    </div>
            </div>
        </div>
    </section>
    )
}
export default Roadmap;