import Navbar from "./Navbar";
import { Link } from 'react-router-dom';

function Header() {
    return (
      <header>
      <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-box-round">
          <div className="container">
              <Link  to={'/'}  title="Logo" className="logo scroll">
                  <img src="/img/logo-white.png" alt="logo" className="logo-light default" />
                  <img src="/img/logo-black.png" alt="logo" className="logo-dark"/>
              </Link>
  
              <div className="collapse navbar-collapse" id="megaone">
                  <Navbar/>
              </div>
  
                  <a href="/#top" className="d-inline-block sidemenu_btn" id="sidemenu_toggle">
                      <span></span>
                      <span></span>
                      <span></span>
                  </a>
          </div>
      </nav>
  
      <div className="side-menu hidden">
          <div className="inner-wrapper">
              <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
              <nav className="side-nav w-100">
                  <ul className="navbar-nav">
                      <li className="nav-item">
                          <a className="nav-link scroll" href="#mint">Mint </a>
                      </li>
                      <li className="nav-item">
                      <a className="nav-link scroll" href="#mission">Mission </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link scroll" href="#roadmap">Roadmap </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link scroll" href="#team">Team </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link scroll" href="#about">About </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link scroll" href="#polls">Polls</a>
                      </li>
                  </ul>
              </nav>
              <div className="side-footer text-white w-100">
                  <ul className="social-icons-simple">
                      <li><a className="instagram-text-hvr" href="#0"><i className="fab fa-discord"></i> </a> </li>
                      <li><a className="twitter-text-hvr" href="#0"><i className="fab fa-twitter"></i> </a> </li>
                  </ul>
                  <p className="text-white">Copyright © 2021 Generative Art, LLC. All Rights Reserved.
                  </p>
              </div>
          </div>
      </div>
      <a id="close_side_menu" href="#top">.</a>
  </header>
  
    );
  }
  
  export default Header;
  