function Team(){
 return (
  <section className="center-block teamsection" id="team" style={{background: "url(img/single-portfolio2.jpg)" }}>
    <div className="container wow fadeInUp">
     <h2 className="teamheading">Team</h2>       
      <div className="row mb-5">
        <div className="col-sm">
          <div className="card">
            <img src="https://dummyimage.com/300x300" alt="" /> 
            <div className="card-body">
            <h5 className="card-title">JOHN</h5>
            <p className="card-text">Lorem ipsum </p>      
            </div>   
          </div>  
        </div>
        <div className="col-sm">
          <div className="card">
            <img src="https://dummyimage.com/300x300" alt="" /> 
            <div className="card-body">
            <h5 className="card-title">JOHN</h5>
            <p className="card-text">Lorem ipsum </p>
            </div>      
       </div>      
     </div>
     <div className="col-sm">
     <div className="card">
        <img src="https://dummyimage.com/300x300" alt="" /> 
       <div className="card-body">
        <h5 className="card-title">JOHN</h5>
        <p className="card-text">Lorem ipsum </p>      
       </div>     
     </div>
   </div>
   </div>
   <div className="row mt-5" >
     <div className="col-sm">
     <div className="card">
        <img src="https://dummyimage.com/300x300" alt="" /> 
       <div className="card-body">
        <h5 className="card-title">JOHN</h5>
        <p className="card-text">Lorem ipsum </p> 
       </div>
       
       </div>     
     </div>
     <div className="col-sm">
     <div className="card">
        <img src="https://dummyimage.com/300x300" alt="" /> 
       <div className="card-body">
        <h5 className="card-title">JOHN</h5>
        <p className="card-text">Lorem ipsum </p> 
        </div>      
       </div>      
     </div>
     <div className="col-sm">
     <div className="card">
        <img src="https://dummyimage.com/300x300" alt="" /> 
       <div className="card-body">
        <h5 className="card-title">JOHN</h5>
        <p className="card-text">Lorem ipsum </p>  
         </div>    
       </div>     
     </div>    
   </div>
   </div>   
  </section>
 )
}
export default Team;