function Utility(){
 return (<section className="single-items center-block parallax bg-warning" id="utility" style={{background: "url(img/single-portfolio2.jpg)" }}>
 <div className="container">
     <div className="row">
       <div className="col-md-12 center-col">
           <div className="area-heading text-center wow fadeInUp">
               <h3 className="area-title text-capitalize alt-font text-white mb-2 font-weight-900">
                 UTILITY
               </h3>
               <p  className="text-white">Every mint will get you the following:</p>
             </div>
         </div>
     </div>
     <div className="row text-center">
         <div className="col-sm-3">
           <div className="icon-wrapper">
             <img className="img-fluid rounded-circle" alt="" src="https://dummyimage.com/36x36" />
           </div>
           <h4 className="text-white">Unlockable Content</h4>
           <p  className="text-white">
             Token holders will receive the seven-song album as downloadable
             mp3’s via the members-only channel in my Discord server. Upon
             request, token holders will also receive stems for the specific
             NFT songs they hold so that they can create their own remixes, as
             well as a .jpg of the artwork for the specific NFT songs they
             hold.
           </p>
         </div>
         <div className="col-sm-3">
             <div className="icon-wrapper">
                 <img className="img-fluid rounded-circle" alt="" src="https://dummyimage.com/36x36" />
               </div>
           <h4  className="text-white">Physical Merch</h4>
           <p  className="text-white">
             Token holders will receive a limited edition vinyl pressing of the
             seven-song album in the coming months, and additional shipping and
             handling charges may apply when it is time to register for vinyl
             delivery. In the near future, token holders will also have access
             to exclusive Spottie merch and apparel.
           </p>
         </div>
         <div className="col-sm-3">
             <div className="icon-wrapper">
                 <img className="img-fluid rounded-circle" alt="" src="https://dummyimage.com/36x36" />
               </div>
           <h4  className="text-white">IP Rights</h4>
           <p className="text-white">
             Token holders will have a non-exclusive copyright license for the
             specific NFT songs and accompanying artwork that they hold. Read
             the copyright license in its entirety
             <a href="/terms.html">here</a>.
           </p>
         </div>
         <div className="col-sm-3">
             <div className="icon-wrapper">
                 <img className="img-fluid rounded-circle" alt="" src="https://dummyimage.com/36x36" />
               </div>
           <h4 className="text-white">Voting Rights</h4>
           <p  className="text-white">
             Holding any NFT songs from the “I’m Spottie” drop gives you voting
             rights on key questions I will ask in the members-only channel in
             my Discord server, to help me make important career decisions in
             the coming months and years. NFT song holders will help me decide
             what my new music should sound like, who I should collaborate
             with, where I should perform, and much more!
           </p>
         </div>
       </div>
 </div>
</section>
)
}
export default Utility;